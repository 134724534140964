import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "inleiding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#inleiding",
        "aria-label": "inleiding permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Inleiding`}</h1>
    <p>{`Door middel van deze API is het mogelijk om een koppeling te maken met Offsys. Hierdoor is het mogelijk om aanvragen - bijvoorbeeld via de website - rechtstreeks in Offsys te zetten. Ook is het mogelijk om bepaalde informatie uit Offsys te halen.`}</p>
    <br />
    <h2 {...{
      "id": "starten",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#starten",
        "aria-label": "starten permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Starten`}</h2>
    <p>{`Om te starten is er een API Key nodig. Een key is in Offsys te vinden onder het kopje layouts. Hiervoor moet eerst een bepaalde layout geselecteerd worden. Stuur deze key altijd mee in een Authorization header.`}</p>
    <p><strong parentName="p">{` Voorbeeld: `}</strong></p>
    <pre><code parentName="pre" {...{}}>{`curl "https://api.offsys.nl/locations" \\
-X GET \\
-H "Authorization: 37d37675-59cd-431c-8d28-9553de7bae4d"
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      